import React, { useEffect, useState } from "react";
import {Link} from "gatsby";
// Component imports
import Layout from '@components/structure/Layout.jsx';

import Anchor from '@components/content/Anchor';

// Utility imports
import SEO from '@components/utility/SEO.jsx';
import { isEvokeScreenshooting, getEvokeScreenshotState } from '@src/helper';

export const Head = () => (
	<>
        <meta name="robots" content="noindex"></meta>
	</>
)
  
const RegistrationConfirmation = ({location, data}) => {
	const [showDocusign, setShowDocusign]  = useState(false); 
    useEffect(()=>{
		setTimeout(() => {
			const isDocusign = typeof window !== 'undefined' ? window.location.href.indexOf('ed=1&docusign') > 0 : false;
			if(isDocusign || (isEvokeScreenshooting() && getEvokeScreenshotState() === 'docusign')){
				setShowDocusign(true);
				location.docusignURL = 'ed';
			}
		}, 100);
		if(location.state?.docusignURL) {
			setShowDocusign(true);
		}
	},[])

	return (
		<Layout pageClassName="registration-confirmation">
			<SEO title='Enrollment Confirmation | Aurinia Alliance&reg;' description='' />
			<section id="Success" className="cta-row cta-row--white">
				{showDocusign ? (
					<div className="wrapper">
						<h2 className="blue">Almost there!</h2>
						<p>You're now ready to take the next step in your Aurinia treatment journey. A Nurse Case Manager will be in touch soon to help you get started.</p>
						<p>Click the button below to complete your enrollment form now so you can take advantage of all the benefits offered by Aurinia Alliance.</p>
						<Anchor linkClass={'btn btn--purple'} isExternal={true} link={location.state?.docusignURL} requiresHCPModal={false}>ENROLL NOW</Anchor> 
					</div>
				) :
				(
					<div className="wrapper">
						<h2 className="blue">Thank you!</h2>
						<p>Great job taking the next step in your Aurinia treatment journey. Your enrollment form is being processed and a Nurse Case Manager will be in touch with you shortly.</p>
						<Link to='/resources' className='btn btn--purple btn--thank-you'>GO TO RESOURCES</Link>
					</div>
				)}
			</section>
		</Layout>
	);
}

export default RegistrationConfirmation;

